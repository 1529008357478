import React from 'react';

import BlankAnchor from 'components/atoms/BlankAnchor';
import Image from 'next/image';
import useIsDesktopDevice from 'hooks/useIsDesktopDevice';
import Inner from '../Inner';
import { SectionBackground, SectionSpacing } from './enums';
import { StyledSection } from './styles';
import { SectionProps, RenderImageProps } from './types';

const Section: React.FC<SectionProps> = ({
  background = SectionBackground.Light,
  topSpacing = SectionSpacing.VerySmall,
  bottomSpacing = SectionSpacing.VerySmall,
  children,
  as = 'section',
  isWide,
  id,
  sectionType,
  ...props
}) => (
  <StyledSection
    {...{ background, ...props }}
    pt={topSpacing}
    pb={bottomSpacing}
    data-section-type={sectionType}
    as={(as as unknown) as React.ElementType<unknown>}
  >
    {id && <BlankAnchor {...{ id }} />}
    <Inner {...{ isWide }}>
      <RenderBackgroundImage {...{ background }} />
      {children}
    </Inner>
  </StyledSection>
);

const RenderBackgroundImage: React.FC<RenderImageProps> = ({ background }) => {
  const isDesktop = useIsDesktopDevice();

  switch (background) {
    case SectionBackground.HeroBanner: {
      if (!isDesktop) {
        return null;
      }

      return (
        <Image
          src={`/assets/images/${background}.jpg`}
          layout="fill"
          objectFit="cover"
          objectPosition={`${isDesktop ? 'center' : '65%'} center`}
          priority
          quality={100}
        />
      );
    }
    case SectionBackground.CtaBanner: {
      return (
        <Image
          src={`/assets/images/${background}.jpg`}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          quality={100}
        />
      );
    }
    default:
      return null;
  }
};

export default Section;
