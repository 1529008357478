import React from 'react';
import { Box } from 'theme-ui';

import { BlankAnchorProps } from './types';

const BlankAnchor: React.FC<BlankAnchorProps> = ({ id }) => (
  <Box variant="blankAnchor" as="span" data-hashscroll-id={id} />
);

export default BlankAnchor;
