import styled from '@emotion/styled';
import { Box } from 'theme-ui';
import { css } from '@emotion/react';
import { StyledSectionProps } from 'components/styles/Section/types';
import { SectionBackground } from 'components/styles/Section/enums';

export const StyledSection = styled(Box)<StyledSectionProps>`
  position: relative;

  ${({ background, theme }) =>
    background &&
    ((background === SectionBackground.Nude &&
      css`
        background-color: ${theme.colors.springWood};
      `) ||
      (background === SectionBackground.Mute &&
        css`
          background-color: ${theme.colors.zircon};
        `) ||
      (background === SectionBackground.Light &&
        css`
          background-color: transparent;
        `) ||
      (background === SectionBackground.Gray &&
        css`
          background-color: ${theme.colors.alabaster};
        `) ||
      (background === SectionBackground.Orange &&
        css`
          background-color: ${theme.colors.fuelYellow};
          color: ${theme.colors.white};
        `) ||
      // This section was set to ebonyClay aka new blue for reskinning
      (background === SectionBackground.Gradient &&
        css`
          background-image: linear-gradient(
            140deg,
            ${theme.colors.ebonyClay} 50%,
            ${theme.colors.ebonyClay} 100%
          );
          color: ${theme.colors.white};
        `) ||
      (background === SectionBackground.MuteGradient &&
        css`
          background-color: ${theme.colors.white};
          background-image: linear-gradient(
            180deg,
            ${theme.colors.white} 0%,
            ${theme.colors.zircon} 100%
          );
        `) ||
      (background === SectionBackground.HeroBanner &&
        css`
          color: ${theme.colors.white};
          background-color: ${theme.colors.fuelYellow};
        `) ||
      (background === SectionBackground.CtaBanner &&
        css`
          color: ${theme.colors.white};
          background-color: ${theme.colors.ebonyClay};
          > div {
            position: static;
            div {
              position: relative;
            }
          }
        `) ||
      (background === SectionBackground.Image &&
        css`
          position: relative;
          color: ${theme.colors.white};
          background-image: url(/assets/images/image.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          > div {
            z-index: 2;
            position: relative;
          }
          &::after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(
              45deg,
              #262945d2 0%,
              ${theme.colors.fuelYellow}d2 100%
            );
          }
        `))}
  ${({ hasHigherZIndex }) =>
    hasHigherZIndex &&
    css`
      z-index: 2;
      position: relative;
    `}
`;
